import { FC, useState, useCallback, FormEvent } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import { createOrder } from "api/byBit";
import ConfirmOrderStep from "./ConfirmOrderStep";
import SuccessOrderStep from "./SuccessOrderStep";
import { CurrenciesStore, GlobalStore } from "stores";
import type { ByBitCreateOrderReq } from "api/types/bybit";
import type { ModalStepsType, ByBitInstrument } from "helpers/types";

interface ByBitOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  fullLeftCurrencyValue: string;
  leftCurrencyValue: string;
  rightCurrencyValue: string;
  activeInstrument: ByBitInstrument | null;
  tradeType: number;
  handleChangeValue: (val: string) => void;
  cost: number;
  operation: number;
  limitPriceValue: string;
}
const ByBitOrderModal: FC<ByBitOrderModalProps> = ({
  isOpen,
  onClose,
  fullLeftCurrencyValue,
  leftCurrencyValue,
  rightCurrencyValue,
  activeInstrument,
  tradeType,
  handleChangeValue,
  cost,
  operation,
  limitPriceValue,
}) => {
  const { t } = useTranslation();
  const { currencies } = CurrenciesStore;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [step, setStep] = useState<ModalStepsType>("confirm");

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const exchangeData: ByBitCreateOrderReq = {
      category: "spot",
      symbol: activeInstrument!.symbol,
      side: tradeType === 0 ? "Buy" : "Sell",
      orderType: operation === 0 ? "Limit" : "Market",
      qty: tradeType === 0 ? rightCurrencyValue : fullLeftCurrencyValue,
      marketUnit: "baseCoin",
    };
    if (operation === 0) {
      exchangeData.price = limitPriceValue;
    }
    // if (isMaxValue) {
    //   exchangeData.isMaxVolume = true;
    // }

    setIsLoading(true);

    createOrder(exchangeData)
      .then(() => {
        setStep("success");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const resetState = useCallback(() => {
    setStep("confirm");
    if (step === "success") {
      handleChangeValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      <BasicModal
        isOpen={isOpen}
        onExited={resetState}
        onClose={closeModal}
        title={t("ORDER")}
      >
        <>
          {isLoading && <Preloader isStatic />}
          {step === "confirm" && (
            <ConfirmOrderStep
              onSubmit={onSubmit}
              activeInstrument={activeInstrument}
              leftCurrencyValue={leftCurrencyValue}
              rightCurrencyValue={rightCurrencyValue}
              onClose={closeModal}
              tradeType={tradeType}
              cost={cost}
              currencies={currencies}
              isLoading={isLoading}
              operation={operation}
            />
          )}
          {step === "success" && (
            <SuccessOrderStep
              leftCurrencyValue={leftCurrencyValue}
              rightCurrencyValue={rightCurrencyValue}
              activeInstrument={activeInstrument}
              onClose={closeModal}
              tradeType={tradeType}
              cost={cost}
              currencies={currencies}
              operation={operation}
            />
          )}
        </>
      </BasicModal>
    </>
  );
};

export default observer(ByBitOrderModal);
