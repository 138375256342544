const es = {
  BACK: "Espalda",
  BALANCE: "Equilibrio",
  GO_BACK: "Volver",
  CLOSE: "Cerrar",
  COPY: "Copiar",
  COPIED: "¡Copiado!",
  OTHERS: "Otros",
  ALL: "Todos",
  TOTAL: "Total",
  CONFIRM: "Confirmar",
  CONFIRMED: "Confirmado",
  PURCHASE: "Compra",
  SALE: "Venta",
  TRADE: "Intercambio",
  WARNING: "Advertencia",
  SEARCH: "Búsqueda",
  NO_DATA: "Sin datos",
  PAGE: "Página",
  OF: "de",
  TO: "a",
  SHOW_ALL: "Mostrar todo",
  PREVIOUS: "Anterior",
  NEXT: "Siguiente",
  FROM: "desde",

  BUY_CRYPTO: "Comprar cripto",
  SELL_CRYPTO: "Vender cripto",
  BUY_SELL_CRYPTO: "Comprar/Vender cripto",
  PROFILE_MENU: "Menú de perfil",
  METAMASK_MENU: "Menú MetaMask",

  PROFILE_SETTINGS: "Configuración del perfil",
  VERIFICATION: "Verificación",
  ACTIVITY_LOG: "Registro de actividades",
  REFERRALS: "Referencias",
  LOG_OUT: "Salida",
  DISCONNECT_METAMASK_ACCOUNT: "Desconectar la cuenta MetaMask",
  ADD_ANOTHER_WALLET: "Añadir otro monedero",

  TRADE_REVIEW: "Revisar transacción",
  RATE: "Tasa",
  YOU_SPEND: "Usted gasta",
  YOU_RECEIVE: "Recibes",
  FEES: "Comisiones",
  DONT_SHOW_AGAIN: "No volver a mostrar esta confirmación",
  WITHOUT_CONFIMATION: "Continuar sin paso de confirmación",
  CLOSE_MODAL: "cerrar ventana modal",
  CLOSE_SELECT: "cerrar selección",
  TO_SPEND: "Gastar",
  TO_RECEIVE: "Recibir",
  ERROR_NO_AMOUNT: "El importe excede su saldo",
  ERROR_NO_AMOUNT_STAKE: "El importe supera su apuesta",
  SELECT_ASSET: "Seleccionar activo",

  DASHBOARD: "Panel de control",
  STAKING: "Replantear",
  WALLET: "Cartera",
  HISTORY: "Historial",
  FEATURES: "Características",
  FUTURES: "Futuros",
  PROFILE: "Perfil",

  ASSETS: "Activo",
  AMOUNT: "Importe",
  PRICE: "Precio",
  VALUE: "Valor",
  D7_GRAPH: "Gráfico 7d",
  H24: "24h",

  ID: "ID",
  DATE: "Fecha",
  TYPE: "Tipo",
  CURRENCY: "Moneda",
  FEE: "Tasa",

  ACCOUNT_NAME: "Usuario",
  COIN: "Criptomoneda",

  ACTIVITY: "Actividad",
  DEVICE: "Dispositivo",
  BROWSER: "Navegador",
  PLACE: "Ubicación",

  TOTAL_BALANCE: "Saldo total",
  FEE_SCHEDULE: "Tabla de tarifas",
  TRADING_VOLUME_30: "Volumen negociado durante 30 días",
  TRADE_BALANCES: "Saldos de negociación",
  POSITION_VALUATION: "Valoración de posiciones",

  CURRENT_FEE: "Comisión actual",
  NEXT_FEE: "Comisión siguiente",
  MARGIN_TRADE_BALANCE: "Saldo comercial de margen",
  MARGIN_EQUITY: "Equidad de margen",
  USED_MARGIN: "Margen utilizado",
  FREE_MARGIN: "Margen libre",
  MARGIN_LEVEL: "Nivel de margen",
  OPENING_COST: "Valor al inicio del periodo",
  CURRENT_VALUATION: "Valoración actual",
  PROFIT_LOSS: "Beneficio / Pérdida",

  LEDGER: "Resumen",
  ALL_CURRENCIES: "Todas las divisas",
  ALL_TRANSACTIONS: "Todas las transacciones",
  START_DATE_TIME: "Fecha/Hora de inicio",
  END_DATE_TIME: "Fecha/hora final",
  FILTER: "Filtro",
  TRANSFER: "Conversión",
  WITHDRAWAL: "Salida",
  DEPOSIT: "Depósito",

  LOGIN: "Inicio de sesión",
  NEW_IP_REGISTERED: "Nueva IP registrada",
  SIGN_UP: "Registro",

  MARKET: "Mercado",
  LIMIT: "Límite",
  DONE: "Completado",

  ACCOUNT_VERIFICATION: "Verificación de cuenta",
  SCAN_QR_PHONE: "Escanear código QR con la cámara del teléfono",
  ALTERNATIVELY: "Alternativamente",
  CLICK_TO_CONTINUE:
    "Haga clic aquí para continuar utilizando su dispositivo actual",
  REFERRAL_LIST: "Usuarios invitados",
  YOUR_REFERRAL: "Su enlace de referencia",
  COPY_LINK: "Enlace de copia",

  EMAIL_ADDRESS: "Dirección de correo electrónico",
  CONFIRMED_UP: "Confirmado",
  VERIFICATION_STATUS: "Estado de verificación",
  UNVERIFIED: "No verificado",
  PROVIDE_INFO:
    "Antes de poder depositar y retirar fondos, facilite la siguiente información y documentos acreditativos para verificar su cuenta.",
  VERIFY: "Verificar",
  DEFAULT_CURRENCY: "Moneda por defecto",
  SECURITY_SETTINGS: "Configuración de seguridad",
  PASSWORD: "Contraseña",
  CHANGE: "Cambiar",
  TWO_FACT_AUTH: "Autenticación de dos factores",
  DISABLED: "Desactivado",
  ENABLED: "Activado",
  TWO_FACT_RECOMMEND:
    "Recomendamos encarecidamente proteger su cuenta con la autenticación de dos factores (2FA)",
  ACTIVITY_TYPE: "Tipo de actividad",
  VERIFY_IDENTITY:
    "Verifica tu cuenta para acceder a más funciones y límites más altos",
  IN_PROGRESS: "En proceso",
  FINISHED: "Completado",
  PROVIDE_PERSONAL_INFO: "Proporcionar información personal",
  MINUTOS: "minutos",
  REQUIRES: "Requerido",
  PROVIDE: "Proporcionar",
  PROVIDE_FINANCIAL_INFO: "Proporcionar información financiera",
  A_FEW_DAYS: "Algunos días",

  REQUIREMENTS: "Requisitos",
  COLLAPSE_REQUIREMENTS: "Colapsar todos los requisitos",
  EXPAND_REQUIREMEN: "Expandir todos los requisitos",

  CRYPTO_DEPOSIT: "Depósitos de criptomoneda",
  CRYPTO_OPERATIONS: "Compra/venta/conversión de criptodivisas",
  CRYPTO_WITHDRAWALS: "Retiros de criptomonedas",
  BANK_TRANSFERS: "Transferencias bancarias",
  TRADE_WITH_MARGIN: "Margen de las operaciones",
  STACKING_REWARDS: "Staking",
  CRYPTO_TRADE: "Negociar cripto futuros",
  UNLIMITED: "Ilimitado",
  DAY_K: "$5K/día",
  DAY_HIGH_K: "$1K/día",
  DAY_MONTH_K: "$100K/día, $500K/mes",

  AMOUNT_TO_STAKE: "Importe a apostar",
  AMOUNT_TO_UNSTAKE: "Importe a retirar apostado",
  YEARLY_REWARD: "Recompensa anual",
  APPROXIMATELY: "Aproximada",
  REWARDS: "Recompensa",
  WEEKLY: "Semanal",
  UNSTACKING_FEE: "Cargo por cancelación de stake",
  UNSTAKE_CONFIRMATION: "Confirmación de cancelación de apuesta",
  UNSTAKE_CONFIRM: "¿Está seguro de que desea retirar su apuesta",
  UNSTAKE_INITIATED: "Cancelación de apuesta iniciada",

  CHOOSE_METHOD_ASSET: "Seleccione un activo o método de pago",
  CHECK_DEPOSIT_ADDRESS_1: "Por favor, vuelva a comprobar",
  CHECK_DEPOSIT_ADDRESS_2: "¡Dirección de depósito!",
  SENDING_FUNDS_INFO:
    "El envío de fondos a una dirección incorrecta puede provocar la pérdida irrecuperable de su criptomoneda",
  DEPOSIT_ADDRESS: "Dirección de depósito",
  CONFIRM_PROCEED_INFO:
    "Entiendo que el procedimiento puede tardar hasta 48 horas en completarse",
  COPY_DEPOSIT_ADDRESS: "Copia de la dirección de depósito",
  SENDING_FUNDS_CASH_INFO:
    "Por favor, vuelva a comprobar la dirección de depósito en euros",
  SENDING_CASH_FUNDS_INFO:
    "El envío de fondos a una dirección incorrecta puede provocar la pérdida irrecuperable de su dinero",

  WITHDRAW: "Retirar dinero",
  CHOOSE_METHOD: "Seleccionar método de pago",
  WITHDRAW_WARN_1: "Retirar sólo",
  WITHDRAW_WARN_2: "direcciones",
  WITHDRAWAL_WARN_3:
    "Las retiradas a direcciones generadas para otras redes pueden provocar la pérdida de fondos",
  WITHDRAW_REVIEW: "Verificación de retirada",

  TOTAL_REWARDS: "Remuneración total",
  STAKING_BALANCE: "Saldo de apuestas",
  STAKING_ASSETS: "Activos de apuestas",
  STAKING_OPTIONS: "Opciones de apuesta",
  REWARD_PERCENT: "Porcentaje de recompensa",
  TRANSACTIONS: "Transacciones",
  ASSET: "Activo",
  COST: "Coste",
  STATUS: "Estado",
  REWARD: "Remuneración",

  IN_ORDERS: "En pedidos",
  FREE: "Libre",
  MARGIN_PORTFOLIO: "Valor de la cartera de margen (USD)",
  USED_FREE_MARGIN: "Margen utilizado/libre",
  UNREALIZED_P_L: "P/L no realizado",
  FUNDING_LIMITS: "Límites de financiación",
  WITHDRAWAL_LIMITS: "Límites de retiro",
  CASH: "Efectivo",
  CRYPTO: "Criptomoneda",
  DAILY_DEPOSITS: "Depósitos diarios",
  DAILY_WITHDRAWALS: "Retiros diarios",
  MONTHLY_DEPOSITS: "Depósitos mensuales",
  MONTHLY_WITHDRAWALS: "Retiros mensuales",
  YEARLY_DEPOSITS: "Depósitos anuales",
  YEARLY_WITHDRAWALS: "Retiros anuales",
  DAILY: "A diario",
  MONTHLY: "Mensual",
  YEARLY: "Anualmente",
  RECENT_TRANSACTIONS: "Transacciones recientes",
  CRYPTO_BALANCES: "Saldo de criptomonedas",
  CASH_BALANCES: "Saldo de divisas",

  NEW_ORDER: "Nuevo pedido",
  ORDERS: "Pedidos",
  POSICIONES: "Posiciones",
  TRADES: "Operaciones",
  PAIR: "Par",
  EJECUTADO: "Ejecutado",
  VOLUMEN: "Volumen",
  ORDER_TYPE: "Tipo de orden",
  CURRENCY_PAIR: "Par de divisas",
  LEVERAGE: "Apalancamiento",
  SCHEDULED_START: "Inicio programado",
  SCHEDULED_EXPIRE: "Vencimiento programado",
  REASON: "Motivo",
  VOLUME_EXECUTED: "Volumen realizado",
  AVERAGE_PRICE: "Precio medio",
  OPENED: "Abierto",
  CLOSED: "Cerrado",
  MAKER_FEE: "Comisión de creación",
  TAKER_FEE: "Comisión de aceptación",
  TOTAL_FEE: "Comisión total",
  OPEN: "Abierto",
  TERMS: "Términos y condiciones",
  ORDER: "Pedido",
  TO_ORDER: "Orden",
  SELL: "Venta",
  FOR: "para",
  REQUESTED_DOCUMENTS: "Documentos solicitados",
  DOCUMENTS: "Documentos",
  FILL_FORM: "Rellenar formulario",

  ACCOUNT_LOGIN: "Inicio de sesión en la cuenta",
  EMAIL: "Correo electrónico",
  ENTER_EMAIL: "Introduzca su correo electrónico",
  ENTER_PASSWORD: "Introduzca su contraseña",
  CURRENT_PASSWORD: "Contraseña actual",
  NEW_PASSWORD: "Nueva contraseña",
  ENTER_CURRENT_PASSWORD: "Ingrese su contraseña actual",
  RESET_PASSWORD: "Restablecer contraseña",
  FORGOT_PASSWORD: "Olvidó su contraseña",
  REFERRAL_CODE: "Código de referencia",
  OR: "O",
  CONTINUE_WITH: "Continuar",
  NO_ACCOUNT: "¿No tiene cuenta?",
  HAVE_ACCOUNT: "¿Ya tiene una cuenta?",
  CREATE_ACCOUNT: "Crear una cuenta",
  LOG_IN: "Iniciar sesión",
  AGREE_WITH: 'Al hacer clic en el botón "Registrarse", acepta',
  TERMS_AND_CONDITIONS: "Términos y condiciones",
  CONTINUE: "Continuar",
  PASSWORD_RECOVERY: "Recuperación de contraseña",
  LEARN_MORE: "Más información",
  EMAIL_NOT_RECEIVE: "¿No ha recibido el correo electrónico?",
  RESEND_IN: "Reenviar",
  RESEND_EMAIL: "Reenviar correo electrónico",
  CREATE_NEW_PASSWORD: "Crear una nueva contraseña",
  ENTER_NEW_PASSWORD: "Introducir nueva contraseña",
  CONFIRM_PASSWORD: "Confirmar contraseña",
  CREATE: "Crear",
  PASSWORD_SUCCESS_CHANGE: "La contraseña se ha cambiado correctamente",
  SECURITY_CHANGE_WARNING:
    "Con el fin de proteger su cuenta, los reintegros se desactivarán durante 24 horas después de realizar el cambio",
  RESET_LINK_SENT:
    "Se ha enviado un correo electrónico con un enlace de restablecimiento de contraseña a",
  CLICK_TO_RESEND:
    "Haga clic en el botón de abajo para reenviar el correo electrónico",
  EMAIL_CONFIRMED: "Correo electrónico confirmado - ¡Bienvenido a FNT Crypto!",
  EMAIL_CONFIRMED_TEXT:
    "¡Grandes noticias! Tu correo electrónico ha sido confirmado y tu cuenta de FNT Crypto ya está activa. Prepárate para sumergirte en nuestra comunidad",
  DEAR: "Querido",
  EXPLORE_FNT: "Explora FNT Crypto",
  SEND_VERIFY_TO_EMAIL: "Enviar verificación al correo electrónico",
  NO_WALLETS_YET: "No hay monederos todavía",
  ADD_WALLET: "Añadir un monedero",
  SELECT_NETWORK: "Seleccionar red",
  VERIFY_EMAIL: "Por favor, compruebe su correo electrónico",
  EMAIL_SENT: "¡Ya casi está! Hemos enviado un correo electrónico a",
  EMAIL_SENT_INFO:
    "Solo tienes que hacer clic en el enlace del correo electrónico que se ha enviado a tu correo electrónico para completar tu registro. Si no lo ves, es posible que tengas que comprobar tu carpeta de correo no deseado",
  CANT_FIND_EMAIL: "¿Sigues sin encontrar el correo electrónico?",
  NEED_HELP: "¿Necesitas ayuda?",
  CONTACT_US: "Póngase en contacto con nosotros",
  SOMETHING_GOES_WRONG: "Algo ha ido mal...",
  EMAIL_NOT_CONFIRMED_TEXT:
    "Vuelva a intentarlo más tarde o póngase en contacto con el servicio de asistencia en caso de que el correo electrónico siga sin confirmarse",
  PROCEED_INFO: "El procedimiento puede tardar hasta 48 horas",
  LIMIT_ERROR:
    "El precio límite no puede ser superior o inferior al 10% del precio de mercado",
  SEND: "Enviar",
  AVAILABLE: "Disponible",
  NOT_AVAILABLE: "No disponible",
  LIMIT_PRICE: "Precio límite",
  EXCHANGE: "Intercambio",
  BUY_PRODUCT: "Comprar producto",
  COMMISSION: "Honorarios",
  SPEND: "Gastar",
  LIMIT_ORDER_PRICE: "Precio límite de la orden",
  OPEN_ORDERS: "Órdenes abiertas",
  ORDERS_HISTORY: "Historial de órdenes",
  CANCELED: "Cancelada",
  EXPIRADO: "Expirado",
  PROGRESS: "Progreso",
  ACTIVE: "Activo",
  CANCELLED: "Cancelado",
  NO_ACTIVE_STAKES: "No se encontraron stakes activas",
  MARKET_PURCHASE_CONFIRMED: "Compra a precio de mercado confirmada",
  MARKET_SALE_CONFIRMED: "Venta a precio de mercado confirmada",
  LIMIT_ORDER_CONFIRMED: "Orden limitada colocada",
  ACCURAL_PERIOD: "Periodo de acumulación",
  GAMES: "Juegos",
  CHOOSE_GAMES: "Elegir juegos",
  CARDS: "Tarjetas",
  ENABLE: "Activar",
  DISABLE: "Desactivar",
  BALANCE_BEFORE_TRANSACTION: "Saldo antes de la transacción",
  CURRENTLY_UNAVAILABLE_PRODUCT:
    "Lo sentimos, pero el producto no está disponible actualmente",
  HIDE_EMPTY_WALLETS: "Ocultar carteras vacías",
  RESUBMIT: "Volver a enviar",
  NO_WALLETS_FOUND: "No se encontraron billeteras",
  EARNING: "Ganador",

  EMAIL_INVALID: "Dirección de correo electrónico no válida",
  EMAIL_REQUIRED: "Dirección de correo electrónico requerida",
  PASSWORD_INVALID: "Contraseña no válida",
  PASSWORD_REQUIRED: "Contraseña requerida",
  PASSWORD_DIFFERENT: "Las contraseñas no coinciden",
  ACCEPT_TERMS_REQUIRED: "Requiere la aceptación de los Términos de Uso",
  FIAT: "Fíat",
  CONTACT_SUPPORT: "Contactar con soporte",
  MERCHANT: "Comerciante",
  CARD_AMOUNT: "Monto de la tarjeta",
  TRANSACTION_AMOUNT: "Monto de la transacción",
  CURRENTLY_UNAVAILABLE_CURRENCY:
    "Lo sentimos, pero esta moneda actualmente no está disponible para transacciones",
  BALANCES: "Saldos",
  WITHDRAW_CARD: "Retiro de tarjeta",
  TOP_UP_CARD: "Recarga de tarjeta",
  ORDER_CARD: "pedido de tarjeta",
  LAST_EXCHANGE_TRANSACTIONS: "Últimas transacciones de cambio",
  MINIMUM_DEPOSIT: "Mín. Depósito",
  MAXIMUM_DEPOSIT: "Máx. Depósito",
  BANK_TRANSFER_REFUND: "Reembolso",
  BANK_TRANSFER_DEPOSIT: "Depósito mediante transferencia bancaria",
  BANK_TRANSFER_WITHDRAW: "Retiro mediante transferencia bancaria",
  EXCHANGE_PROVIDER_COMMISSION: "Comisión del proveedor de cambio",
  OVERPAYMENT: "Sobrepago",
  BET: "Apuesta",
  TOURNAMENT_WINNING: "Ganar el torneo",
  DETAILS: "Detalles",
  ACCEPT: "Aceptar",
  COOKIES_DESC:
    "Este sitio web utiliza cookies para garantizar que obtenga la mejor experiencia en nuestro sitio web.",
  DELIVERY_ADDRESS: "Dirección de entrega",
  ADD_DELIVERY_ADDRESS: "Agregar dirección de entrega",
  ADD_NEW_DELIVERY_ADDRESS: "Agregar nueva dirección de entrega",
  DELIVERY_METHOD: "Método de entrega",
  STANDARD_MAIL: "Correo estándar",
  COURIER: "Mensajero",
  ADDRESS: "Dirección",
  ENTER_ADDRESS: "Ingrese una dirección",
  MANAGE_CARD: "Gestión de tarjetas",
  CRYPTO_CARDS: "Tarjetas criptográficas",
  GIFT_CARDS: "Tarjetas regalo",
  MY_CRYPTO_CARDS: "Mis tarjetas criptográficas",
  BUY_NEW_CARD: "Comprar una tarjeta nueva",
  LAST_TRANSACTIONS: "Últimas transacciones",
  COMING_SOON: "Próximamente",
  TOTAL_PRICE: "Precio total",
  DELIVERY_RESTRICTIONS:
    "Actualmente la entrega está disponible sólo para países del EEE, Reino Unido y Suiza (no se mostrarán sus direcciones de otros países)",
  OVERVIEW: "Resumen",
  CARD_COST: "Coste de la tarjeta",
  PLASTIC_COST: "Costo del plástico",
  DELIVERY_COST: "Costo de envío",
  ORDER_PLASTIC_CARD: "Solicite una tarjeta de plástico",
  TWO_FA_FOR_DETAILS:
    "Necesitas habilitar 2fa para ver los detalles de la tarjeta",
  TWO_FA_FOR_PIN: "Necesitas habilitar 2fa para ver el código PIN",
  CARD_DETAILS: "Detalles de la tarjeta",
  VIEW_DETAILS: "Ver detalles",
  PURCHASE_PLASTIC: "Comprar plástico",
  VIEW_PIN: "Ver PIN",
  CARD_TOP_UP_LIMITS: "Límites de recarga de tarjeta",
  CARD_TOP_UP_LIMITS_UPGRADE:
    "Estos son límites de tarjeta que puedes aumentar aumentando tu nivel de verificación",
  DAILY_TOP_UPS: "Recargas diarias",
  MONTHLY_TOP_UPS: "Recargas mensuales",
  YEARLY_TOP_UPS: "Recargas anuales",
  INCREASE_LIMITS: "Aumentar límites",
  TOTAL_TO_SPEND: "Total a gastar",
  TOTAL_TO_RECEIVE: "Total a recibir",
  AMOUNT_TO_RECEIVE: "Cantidad a recibir",
  AMOUNT_TO_RECEIVE_BEFORE_FEE: "Cantidad a recibir antes de la tarifa",
  AMOUNT_TO_SPEND: "Cantidad a gastar",
  AMOUNT_TO_SPEND_BEFORE_FEE: "Cantidad a gastar antes de pagar la comisión",
  TOP_UP: "Recargar",
  ENTER_AMOUNT: "Ingrese monto",
  SELECT_AMOUNT: "Seleccione cantidad",
  HOW_TO_USE: "Cómo utilizar",
  CRYPTO_CARD_ACTIVATE:
    "La tarjeta se activará al finalizar el proceso de compra.",
  LIMITS: "Límites",
  KYC_VERIFICATION_INFO_REQUIERED:
    "Se requiere verificación de cuenta e información personal adicional",
  KYC_WARNING_COUNTRY:
    "¡Atención! Las tarjetas criptográficas sólo están disponibles para residentes de los países del EEE, Reino Unido y Suiza.",
  KYC_VERIFICATION_REQUIRED:
    "- La compra de tarjetas criptográficas requiere verificación de cuenta en el nivel Medio o superior, y no se preocupe, solo le llevará entre 4 y 5 minutos.",
  KYC_VERIFICATION_DOCS_REQUIERED:
    "- Una vez que se verifica una cuenta en el nivel Medio o superior, también se requiere información personal adicional del usuario y confirmación de la dirección. (Verificar y confirmar la información puede tardar un día o más)",
  KYC_VERIFICATION_APPROVE_IN_PROCESS:
    "Actualmente sus documentos están enviados y están en revisión... Puede tomar algún tiempo",
  KYC_VERIFICATION_ACCESS_REQUEST:
    "- En la última etapa deberá solicitar acceso. (La aprobación del acceso puede tardar una semana o más)",
  KYC_VERIFICATION_DOCS_APPROVED:
    "¡Su información personal de usuario adicional y la confirmación de su dirección han sido aprobadas! ¡Ahora puede solicitar acceso para utilizar este servicio!",
  KYC_VERIFICATION_ERROR:
    "Su solicitud de acceso ha sido denegada. Por favor contacte",
  KYC_REQUEST_PENDING:
    "La aprobación de acceso está pendiente. Debe esperar. (Esto puede tardar una semana o más)",
  FILL_FORMS: "Rellenar formularios",
  VERIFY_ACCOUNT: "Verificar cuenta",
  REQUEST_ACCESS: "Solicitar acceso",
  KYC_VERIFICATION_SUPPORT: "soporte.",
  FORM_VERIFICATION_ERROR:
    "Sus documentos han sido rechazados. Comuníquese con nuestro",
  FORM_VERIFICATION_SUPPORT: "soporte",
  FORM_VERIFICATION_ERROR_END: " para obtener más detalles.",
  UPLOAD: "Subir",
  CARD_TRANSFER_CASHBACK: "Reembolso",
  CASHBACK: "Reembolso",
  REJECT_REASON: "Motivo del rechazo:",
  PREVIOUS_REJECT_REASON: "Motivo de rechazo anterior:",
  PAY_METHOD: "Método de pago",
  DIVIDEND: "Dividendo",
  PAY_WITH: "Pagar con",
  INCLUDING_FEES: "Tarifas incluidas",
  NETWORK_FEE: "Tarifa de red",
  SERVICE_FEE: "Tarifa de servicio",
  MINIMUM_AMOUNT_IS: "La cantidad mínima es",
  AMOUNT_MUST_BE_LESS_THAN: "La cantidad debe ser menor que",
  ERROR_CREATING_ADDRESS: "Error al crear una nueva dirección de billetera",
  ERROR_CREATING_ADDRESS_CRYPTO: "(es necesario para comprar criptomonedas)",
  TRY_AGAIN: "Inténtalo de nuevo",
  ACTIVATE: "Activar",
  CARD_ACTIVATION: "Activación de tarjeta",
  ACTIVATE_PLASTIC: "Activar plástico",
  ENTER_CODE_TO_ACTIVATE_CARD: "Ingrese el código para activar la tarjeta",
  ENTER_CODE: "Ingrese código",
  SUCCESS_CARD_ACTIVE: "La activación de la tarjeta fue exitosa",
  UP_TO: "Hasta",
  YOU_SPENT: "Gastaste",
  LOGGED_FROM_ADMIN_PANEL:
    "Ha iniciado sesión en su cuenta de usuario desde el panel de administración",
  NEW_WALLET_ADDRESS_CREATING:
    "Se está creando una nueva dirección de billetera. Espere...",
  TOP_UP_FEES: "Tarifa de depósito",
  WITHDRAW_FEES: "Tarifa de retiro",
  TRANSACTION_FEES: "Tarifa de transacción",
  UNKNOWN_PAYBIS_ERROR:
    "Se ha producido un error desconocido al cargar el widget de Paybis. Lo más habitual es que no esté disponible por motivos legales.",
  CARD_LIMITS: "Límites de la tarjeta",
  MONTHLY_TRANSACTIONS: "Transacciones mensuales",
  CODE: "Código",
  FACE_VALUE: "Valor nominal",
  CARD_INFO: "Información de la tarjeta",
  DRAG_AND_DROP_INFO:
    "Arrastre y suelte el archivo aquí o haga clic para seleccionar el archivo",
  ASSET_OR_PAYMENT_METHOD: "Activo o método de pago",
  CRYPTO_CARD_REVIEW: "Reseña de la tarjeta de criptomonedas",
  GIFT_CARD_REVIEW: "Reseña de la tarjeta de regalo",
  CHANGE_PASSWORD: "Cambiar contraseña",
  PASSWORD_CHANGED_SUCCESSFULLY: "Contraseña cambiada exitosamente",
  TRANSACTION_REVIEW: "Revisión de transacción",
  WITHDRAW_WAS_SUCCESSFUL: "El retiro fue exitoso",
  TOP_UP_WAS_SUCCESSFUL: "La recarga fue exitosa",
  NOW_YOUR_BALANCE: "Ahora el saldo de tu tarjeta es",
  YOU_RECEIVED: "Recibiste",
  DEPOSIT_VERIFY_REQUIRED:
    "El depósito requiere verificación de cuenta en el nivel SENIOR.",
  BANK: "Banco",
  BANK_ADDRESS: "Dirección del banco",
  DEPOSIT_WARNING:
    "¡ADVERTENCIA! ¡Es OBLIGATORIO completar este 'código' en los detalles de la transacción! De lo contrario, la transacción no se realizará",
  PURCHASE_PLASTIC_CARD: "Compra de una tarjeta plástica",
  PLASTIC_CARD_SUCCESS_PURCHASE: "Tarjeta de plástico ordenada correctamente",
  START_EARN_AGAIN_1: "Puede comenzar a ganar recompensas en su",
  START_EARN_AGAIN_2: "nuevamente en cualquier momento.",
  WITHDRAW_VERIFY_REQUIRED:
    "El retiro requiere verificación de cuenta en el nivel MEDIO o superior",
  WITHDRAW_FIAT_VERIFY_REQUIRED:
    "Para retiros, se requiere verificación de cuenta en el nivel SENIOR.",
  SELL_CRYPTO_VERIFY_REQUIRED:
    "Para vender criptomonedas se requiere verificación de cuenta en el nivel MIDDLE.",
  BUY_CRYPTO_VERIFY_REQUIRED:
    "Para comprar criptomonedas se requiere verificación de cuenta de nivel MIDDLE.",
  ENTER_NAME: "Ingresa el nombre",
  ENTER: "Ingresar",
  ENTER_DETAILS: "Ingrese detalles",
  PROCEDURE_WARN:
    "Entiendo que el procedimiento puede tardar hasta 48 horas en completarse.",
  TWO_FA_FOR_WITHDRAW: "Debes habilitar 2fa para usar Retirar",
  TWO_FA_FOR_PIN_CODE: "Ingrese el código 2FA para ver el código PIN",
  CARD_BALANCE: "Saldo de la tarjeta",
  TWO_FA_FOR_CARD_DETAILS:
    "Ingrese el código 2FA para ver los detalles de la tarjeta",
  CARD_NUMBER: "NÚMERO DE TARJETA",
  EXPIRY_DATE: "FECHA DE VENCIMIENTO",
  SECURITY_CODE: "CÓDIGO DE SEGURIDAD",
  CARD_HOLDER: "Titular de la tarjeta",
  CARD_PIN_CODE: "Código PIN de la tarjeta",
  KEEP_PIN_CODE_SAFE:
    "Mantenga su código PIN confidencial, nunca lo comparta con nadie.",
  TRANSACTION_EXCEEDS_LIMITS_DAILY: "La transacción excede sus límites diarios",
  TRANSACTION_EXCEEDS_LIMITS_MONTHLY:
    "La transacción excede sus límites mensuales",
  TRANSACTION_EXCEEDS_LIMITS_YEARLY:
    "La transacción excede sus límites anuales",
  OUT_OF_LIMITS: "Excediendo los límites",
  INCREASE_LIMITS_TEXT:
    "Para aumentar los límites, puede pasar la verificación a un nivel superior",
  SENIOR_VERIFICATION: "Senior verificación",
  SENIOR_VERIFICATION_TEXT_1:
    "Para completar la verificación Senior y acceder a depósitos y retiros en moneda fiduciaria, utilice el siguiente enlace para programar una videollamada con uno de nuestros especialistas.",
  SENIOR_VERIFICATION_TEXT_2:
    "Asegúrese de que su pasaporte u otro documento válido esté listo para leer el código MRZ y el reconocimiento facial. Si reside en un país diferente al de su ciudadanía, proporcione también un permiso de residencia de ese país durante la videollamada.",
  SENIOR_VERIFICATION_TEXT_3:
    "Proporcionar información falsa se reenviará a las autoridades pertinentes, bancos y proveedores de servicios KYC/AML con anticipación, lo que resultará en la aparición de marcas especiales en su perfil.",
  SENIOR_VERIFICATION_SCHEDULE:
    "Para programar una videollamada cuando le resulte conveniente, utilice el siguiente enlace:",
  SENIOR_VERIFICATION_LINK_1:
    "Una vez que hayas reservado un horario, utiliza el enlace",
  SENIOR_VERIFICATION_LINK_2: "para acceder a la videollamada.",
  SENIOR_VERIFICATION_ADVISE:
    "Recomendamos prepararse con anticipación para la videollamada y garantizar una calidad de video óptima para el proceso de verificación.",
  SENIOR_VERIFICATION_QUESTIONS_1:
    "Si tiene alguna pregunta o surge algún problema, no dude en ponerse en contacto con nuestro",
  SENIOR_VERIFICATION_QUESTIONS_2: "equipo de soporte.",
  SCAN_QR_BY_AUTH_APP:
    "Escanea el código QR usando la aplicación de autenticación",
  MANUALY_ENTER_TO_AUTH_APP:
    "O ingrese manualmente este secreto en la aplicación de autenticación",
  ENTER_CODE_FROM_AUTH_APP:
    "Ingrese el código de la aplicación de autenticación a continuación",
  ACCOUNT_VERIFICATION_REQUIRED: "Se requiere verificación de cuenta",
  NO_CARDS_FOUND: "No se encontraron tarjetas",
  CHOOSE_CARDS_DESIGN: "Elegir el diseño de la tarjeta",
  NO_CARDS_ACTIVE_YET: "Aún no tienes ninguna tarjeta criptográfica activa",
  NO_GIFT_CARDS_ACTIVE_YET: "Aún no tienes ninguna tarjeta de regalo activa",
  SUBMIT: "Enviar",
  ERROR_LOADING_GAME: "Error al cargar el juego",
  REFRESH: "Actualizar",
  PURCHASE_CARD_RESTRICTION:
    "Para comprar una tarjeta se requiere verificación de cuenta en el nivel Junior o superior, y no te preocupes, solo toma alrededor de 2 minutos.",
  BUY_CARD: "Comprar tarjeta",
  BUY_SAME_CARD: "Compra la misma tarjeta",
  CARD_TITLE: "Título de la tarjeta",
  SAVE: "Guardar",
  EDIT: "Editar",
  CARD_NOTES: "Notas para la tarjeta",
  NO_PENDING_DOCS: "No hay solicitudes de documentos pendientes",
  NOTE: "Nota",
  SELL_CRYPTO_NOTE:
    "ПLa venta no será automática, deberás realizar una transferencia a la billetera proporcionada por el proveedor al final.",
  BUY: "Comprar",
  REQUIRED_FIELD: "Campo Obligatorio",
  INVALID_PHONE_NUMBER: "Número de teléfono inválido",
  CONNECTED: "Conectado",
  CONNECT_ACCOUNT: "Conectar cuenta",
  MAIN: "Principal",
  CONNECTED_ACCOUNT: "Cuenta conectada",
  AMOUNT_TO_DEPOSIT: "Monto a depositar",
  ERROR_METAMASK_CHAIN:
    "Lo sentimos, pero solo admitimos depósitos en MetaMask con ETH. Cambie su cuenta conectada en ETH.",
  INTERNAL_TRANSFER: "Transferencia interna",
  RECIPIENTS_EMAIL: "Correo electrónico del destinatario",
  TRANSFER_NOTE: "Nota",
  TRANSFER_REVIEW: "Revisión de traducción",
  SPENT: "Gastado",
  RECEIVED: "Recibió",
  INITIATED: "Iniciado",
  NO_CRYPTO_WALLETS_FOUND:
    "No se encontraron billeteras. Debe crear al menos una para usar este servicio.",
  GO_TO_MAIN_PAGE: "Ir a la página principal",
  ORDER_BOOK: "Libro de pedidos",
  RECENT_TRADES: "Últimas operaciones",
  ADD_NOTE: "Agrega alguna nota personalizada a tu tarjeta",
  TIME: "Tiempo",
  SPOT_PAIRS: "Spot parejas",
  SIDE: "Dirección",
  IBAN_TRANSACTIONS: "Transacciones IBAN",
  ACTIVE_STAKES: "Apuestas activas",
  NEXT_REWARD_DATE: "Fecha de la próxima recompensa",
  WALLET_BALANCE: "Saldo de billetera",
  RESERVED: "Reservado",
  BONUS: "Bonificación",
  RECIPIENT: "Beneficiario",
  MARKET_ORDER_CONFIRMED: "Orden de mercado confirmada",
  YOU_WILL_RECEIVE: "Recibirás",
  CREATED: "Creado",
  LAST_UPDATE: "Última actualización",
  LINK_WEB_ACCOUNT: "Vincular cuenta web",
  TO_ADDRESS: "A la dirección",
  WIN: "ganancias",
  PROFIT: "Beneficio",
  TRANSFER_BALANCE: "Transferir saldo",
};

export default es;
